import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic02 from '../assets/images/pic02.jpg'

const SalaryStructures = props => (
  <Layout>
    <Helmet>
      <title>Salary Structures - Horovatin Consulting Ltd.</title>
      <meta name="description" content="Salary Structures Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Salary Structures</h1>
          </header>
          <span className="image main">
            <img
              src={pic02}
              alt="Alpine view in the Kananaskis valley. Mountains soar behind a large foreground shale boulder, and alpine trees reach into the clear blue sky"
            />
          </span>
          <p>
            Well defined salary structures allow you to be competitive for
            talent while maintaining equity across the organization. We can work
            with you to set your salary structure and define jobs that show
            career progressions and growth opportunities.
          </p>
          <p>
            Good salary structures will drive engagement and enhance retention,
            all of which will be reflected in a growing bottom line.
          </p>
        </div>
      </section>
    </div>
  </Layout>
)

export default SalaryStructures
